import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHeadnoteById } from '../../../data-access';
import { Alert, Button } from '../../../ui-base';
import { CardPanel, DocViewer, LoadingImage } from '../../../ui-elements';
import { toast } from '../../../util';
import { exportToPDF } from '../../../util/pdf-tools';
import PageHeader from '../page-header/PageHeader';
import {
  getFileName,
  getFormatedCitedCases,
  getHeadnoteFormattedDocConfig,
  getPartyName,
  getSpecialFormatHeadnote,
  getStandardFormatRawText,
  getStandardHeadnote,
} from './util';

// on router path /headnote/details/:docId?refeFrom=
export function HeadnoteDetails() {
  const { docId } = useParams();
  const { data, isLoading, isError, error } = useHeadnoteById(docId || '');
  const [uploadedDocumentLink, setUploadedDocumentLink] = useState('');
  const [headnote, setHeadnote] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const isReferFromCreate = searchParams.get('referFrom') === 'create';
  const [isStandardFormat, setIsStandardFormat] = useState(false);
  const headnoteRef = useRef<HTMLDivElement>(null);
  const uploadedJudgementRef = useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      setUploadedDocumentLink(data.s3_url);
      let updatedHeadnote = '';
      if (isStandardFormat) {
        updatedHeadnote = getStandardHeadnote(data);
      } else {
        updatedHeadnote = getSpecialFormatHeadnote(data);
      }
      setHeadnote(updatedHeadnote);
    }
  }, [data, isStandardFormat]);

  const onToggleChange = (isEnabled: boolean) => {
    setIsStandardFormat(isEnabled);
  };

  const onExportDoc = () => {
    if (!data) {
      return;
    }
    let docConfig = {};
    if (isStandardFormat) {
      docConfig = {
        text: getStandardFormatRawText(data),
        filename: `${getFileName(data.file_name)}-headnote`,
      };
    } else {
      docConfig = getHeadnoteFormattedDocConfig(data);
    }
    exportToPDF(docConfig);
  };

  const onCopyDoc = () => {
    if (!data) return;
    // standard note
    let text = getStandardFormatRawText(data);
    if (!isStandardFormat) {
      // special format
      const { laws, topics, held, cited_cases } = data;
      text = `${laws}. ${topics} \n ${held} \n\n Cited Cases: ${getFormatedCitedCases(cited_cases)} \n\n${getPartyName(data.judgement_metadata)}`;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        toast('Headnote copied to clipboard');
      },
      () => {
        toast('Failed to copy text');
      },
    );
  };

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        console.log(entry.target.id);
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    },
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: '120px 0px 0px 0px',
      threshold: 0.1,
    });

    if (headnoteRef.current) observer.observe(headnoteRef.current);
    if (uploadedJudgementRef.current)
      observer.observe(uploadedJudgementRef.current);

    return () => observer.disconnect();
  }, [observerCallback]);

  const headerActions = (
    <Button variant="primary">
      <Link to="/dashboard/create-headnote">Create New Headnote</Link>
    </Button>
  );
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col">
      <PageHeader
        title="Headnote"
        showBackButton
        actions={isReferFromCreate ? [] : [headerActions]}
      />
      {isError && <Alert type="error">{error.message}</Alert>}
      {isLoading ? (
        <LoadingImage />
      ) : (
        <div className="flex flex-grow overflow-hidden p-4">
          <nav className="flex w-[20%] flex-shrink-0 justify-center overflow-y-auto pt-8">
            <ul className="space-y-2 text-start text-sm">
              <li>
                <button
                  onClick={() => scrollToSection(headnoteRef)}
                  className={clsx(
                    'hover:text-lawmatrix-purple',
                    activeSection === 'headnote' && 'font-bold text-blue-600',
                  )}
                >
                  Headnote
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection(uploadedJudgementRef)}
                  className={clsx(
                    'hover:text-lawmatrix-purple',
                    activeSection === 'uploaded-judgement' &&
                      'font-bold text-blue-600',
                  )}
                >
                  Uploaded Judgement
                </button>
              </li>
            </ul>
          </nav>
          <div ref={scrollContainerRef} className="flex-grow overflow-y-auto">
            <div className="flex w-[72%] flex-col gap-6 lg:min-h-[30rem]">
              <div ref={headnoteRef} className="flex-grow" id="headnote">
                <CardPanel
                  title="Headnote"
                  description={headnote}
                  isDownloadable
                  toggleEnabled={isStandardFormat}
                  onToggleChange={onToggleChange}
                  toggleLabel="Standard format"
                  onExport={onExportDoc}
                  onCopy={onCopyDoc}
                />
              </div>
              <div ref={uploadedJudgementRef} id="uploaded-judgement">
                <DocViewer
                  fileUrl={uploadedDocumentLink}
                  title="Uploaded Judgement"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default HeadnoteDetails;
