import { UserProfileProvider } from '../../providers';
import DashboardRoutes from './DashboardRoutes';
import DasboardHeaderMobile from './header/DashboardHeaderMobile';
import Navbar from './navbar/Navbar';

export function DashboardRoot() {
  return (
    <UserProfileProvider>
      <DasboardHeaderMobile />

      <div className="hidden lg:block">
        <Navbar />
      </div>
      <div className="fixed bottom-0 left-0 right-0 top-20 lg:left-20 lg:top-0">
        <DashboardRoutes />
      </div>
    </UserProfileProvider>
  );
}
export default DashboardRoot;
