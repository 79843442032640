import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHeadnotes } from '../../../data-access';
import { Alert, Button, LawMatrixSpinner } from '../../../ui-base';
import { Table } from '../../../ui-elements';
import { getFormatedDate } from '../../../util';
import PageHeader from '../page-header/PageHeader';
import { HeadnoteHistory, myHeadnoteColumns } from '../util';

export function MyHeadnotes() {
  const { data, error, isError, isLoading, isRefetching } = useHeadnotes();
  const [tableData, setTableData] = useState<HeadnoteHistory[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const formatedData: HeadnoteHistory[] = data.map((d, i) => {
        const dateFormated = getFormatedDate(new Date(d.created_at_utc));
        return {
          detail: d.file_name,
          date: dateFormated,
          sn: i + 1,
          id: d.id,
        };
      });
      setTableData(formatedData);
    }
  }, [data]);

  const handleRowClick = (row: HeadnoteHistory) => {
    navigate(`/dashboard/headnote/details/${row.id}`);
  };

  const headerActions = (
    <Button variant="primary">
      <Link to="/dashboard/create-headnote">Create New Headnote</Link>
    </Button>
  );

  return (
    <div className="w-full">
      <PageHeader title="My Headnotes" actions={[headerActions]} />
      <div className="p-4">
        {isError && <Alert type="error">{error.message}</Alert>}
        {isLoading || isRefetching ? (
          <div className="flex h-10 w-full items-center justify-center">
            <LawMatrixSpinner />
          </div>
        ) : (
          <>
            <Table
              data={tableData}
              columns={myHeadnoteColumns}
              onRowClick={handleRowClick}
              className="hidden lg:table"
            />
            <div className="space-y-4 lg:hidden">
              {tableData.map((item) => (
                <div
                  key={item.id}
                  tabIndex={1}
                  className="grid cursor-pointer grid-cols-2 gap-4 rounded-md p-4 shadow-lg"
                  onClick={() => handleRowClick(item)}
                >
                  <span>File Name:</span>
                  <span>{item.detail}</span>
                  <span>Uploaded Date:</span>
                  <span>{item.date}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MyHeadnotes;
