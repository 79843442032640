import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../ui-base';

interface Props {
  title: string;
  showBackButton?: boolean;
  actions?: ReactNode[];
}

export function PageHeader(props: Props) {
  const { title, showBackButton, actions } = props;
  const navigate = useNavigate();

  const onNavBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-[80px] items-center justify-between border-b border-gray-300 p-4">
      <div className="flex items-center gap-2">
        {showBackButton && (
          <Button variant="icon" onClick={onNavBack}>
            <ArrowLeftIcon className="h-5 w-5" />
          </Button>
        )}
        <h2 className="text-xl">{title}</h2>
      </div>
      {actions && <div className="flex items-center gap-2">{actions}</div>}
    </div>
  );
}

export default PageHeader;
