import { Button } from '../../ui-base';

interface Props {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  title: string;
  actionText: string;
  onClick: () => void;
}

export function FeatureCard(props: Props) {
  const { icon, title, actionText, onClick } = props;
  const CardIcon = icon;
  return (
    <div className="flex flex-col items-center justify-center gap-6 rounded-md border  px-6 py-12 lg:w-60">
      <CardIcon className="h-28 w-28" />
      <span className="text-xl">{title}</span>
      <div>
        <Button variant="primary" onClick={onClick}>
          {actionText}
        </Button>
      </div>
    </div>
  );
}

export default FeatureCard;
