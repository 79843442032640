import {
  ArrowRightStartOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui-base';
import { useAuthUtils, useUser } from '../../../util';
import Links from './Links';
import { navItems } from './util';

interface Props {
  onClick: () => void;
}

export function NavbarMobile(props: Props) {
  const { logout } = useAuthUtils();
  const { picture } = useUser();

  const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <div className="p-4">
      <Links items={navItems} onClick={props.onClick} isMobile />
      <div className="mt-20 flex flex-col items-center gap-1 py-2">
        {picture ? (
          <img
            className="h-8 w-8 rounded-full text-center"
            src={picture}
            alt="user avatar"
          />
        ) : (
          <UserCircleIcon className="h-5 w-5" />
        )}
        <Button
          variant="icon"
          onClick={onLogout}
          className="flex flex-col justify-center text-xs"
        >
          <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
          <span>Logout</span>
        </Button>
      </div>
    </div>
  );
}

export default NavbarMobile;
