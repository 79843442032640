import React from 'react';

export function LawMatrixIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="scale(0.6)">
        <path
          d="M15.2063 13.4633C15.9273 13.0681 16.7984 13.0607 17.526 13.4438L23.7016 16.6951C24.2843 17.0019 24.2832 17.8366 23.6997 18.1418L17.626 21.3192C16.832 21.7346 15.8863 21.7414 15.0864 21.3375L9.05714 18.2928C8.47016 17.9964 8.45609 17.1633 9.03273 16.8472L15.2063 13.4633Z"
          fill="#B0A2EE"
        />
        <path
          d="M16.7715 25.2801C16.7715 24.7876 17.2956 24.472 17.7309 24.7025L24.4266 28.2478C24.9551 28.5277 25.2885 29.0738 25.2958 29.6718L25.4082 38.8562C25.4157 39.4699 24.7691 39.8724 24.2217 39.5947L18.024 36.4505C17.2557 36.0607 16.7715 35.2722 16.7715 34.4106V25.2801Z"
          fill="#B0A2EE"
        />
        <path
          d="M25.2434 18.7891C25.9644 18.3939 26.8356 18.3866 27.5631 18.7696L33.7387 22.0209C34.3214 22.3277 34.3203 23.1624 33.7368 23.4677L27.6631 26.6451C26.8691 27.0604 25.9235 27.0672 25.1236 26.6633L19.0943 23.6186C18.5073 23.3222 18.4932 22.4891 19.0699 22.173L25.2434 18.7891Z"
          fill="#B0A2EE"
        />
        <path
          d="M25.2434 8.08683C25.9644 7.69162 26.8356 7.6843 27.5631 8.06734L33.7387 11.3187C34.3214 11.6254 34.3203 12.4602 33.7368 12.7654L27.6631 15.9428C26.8691 16.3582 25.9235 16.365 25.1236 15.961L19.0943 12.9163C18.5073 12.6199 18.4932 11.7868 19.0699 11.4708L25.2434 8.08683Z"
          fill="#B0A2EE"
        />
        <path
          d="M35.5471 13.3768C36.2682 12.9816 37.1393 12.9743 37.8669 13.3573L44.0425 16.6086C44.6251 16.9154 44.624 17.7501 44.0406 18.0554L37.9668 21.2327C37.1728 21.6481 36.2272 21.6549 35.4273 21.251L29.398 18.2063C28.811 17.9099 28.797 17.0768 29.3736 16.7607L35.5471 13.3768Z"
          fill="#B0A2EE"
        />
        <path
          d="M26.887 30.6411C26.8576 29.644 27.3983 28.7171 28.2807 28.2518L34.5109 24.9667C35.0504 24.6822 35.7001 25.0679 35.7088 25.6778L35.8244 33.8678C35.8406 35.0093 35.1947 36.057 34.1676 36.5553L28.297 39.4037C27.7638 39.6624 27.1412 39.2851 27.1238 38.6927L26.887 30.6411Z"
          fill="#B0A2EE"
        />
        <path
          d="M37.2706 25.1702C37.2412 24.1731 37.7819 23.2462 38.6643 22.7809L44.8945 19.4959C45.434 19.2114 46.0838 19.5971 46.0924 20.207L46.208 28.3969C46.2242 29.5384 45.5783 30.5861 44.5512 31.0844L38.6806 33.9328C38.1474 34.1915 37.5248 33.8142 37.5074 33.2218L37.2706 25.1702Z"
          fill="#B0A2EE"
        />
        <path
          d="M27.0495 43.3694C27.0202 42.3722 27.5609 41.4454 28.4433 40.9801L34.6735 37.695C35.213 37.4105 35.8627 37.7962 35.8713 38.4061L35.987 46.5961C36.0031 47.7376 35.3572 48.7852 34.3302 49.2836L28.4596 52.132C27.9264 52.3907 27.3038 52.0134 27.2864 51.421L27.0495 43.3694Z"
          fill="#B0A2EE"
        />
        <path
          d="M37.2706 37.8426C37.2412 36.8455 37.7819 35.9187 38.6643 35.4534L44.8945 32.1683C45.434 31.8838 46.0838 32.2695 46.0924 32.8794L46.208 41.0694C46.2242 42.2108 45.5783 43.2585 44.5512 43.7569L38.6806 46.6053C38.1474 46.8639 37.5248 46.4867 37.5074 45.8943L37.2706 37.8426Z"
          fill="#B0A2EE"
        />
        <path
          d="M14.0119 36.3426V25.2232C14.0119 24.3259 13.5216 23.5004 12.7336 23.0711L7.47991 20.2088C7.42547 20.1791 7.35913 20.2185 7.35913 20.2805V40.1161C7.35913 40.9502 7.81317 41.7182 8.54399 42.1202L24.4047 50.8456C24.4591 50.8756 24.5257 50.8362 24.5257 50.7741V44.8135C24.5257 43.7393 23.9401 42.7505 22.998 42.2343L14.8606 37.7754C14.3373 37.4887 14.0119 36.9394 14.0119 36.3426Z"
          stroke="#1F1938"
          strokeWidth="2.12401"
        />
      </g>
    </svg>
  );
}

export default LawMatrixIcon;
