import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  fileUrl: string;
  title: string;
}

export function DocViewer(props: Props) {
  const { fileUrl, title } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <details
      className="flex h-full flex-col rounded-md border px-2 py-4"
      open={isOpen}
      onToggle={toggleOpen}
    >
      <summary className="flex h-12 items-center justify-between p-2 pr-8">
        <h2 className="text-xl font-bold text-gray-700">{title}</h2>
        {isOpen ? (
          <ChevronDownIcon className="h-6 w-6" />
        ) : (
          <ChevronRightIcon className="h-6 w-6" />
        )}
      </summary>
      {fileUrl && (
        <div className="flex min-h-[45rem] flex-col border-t border-gray-400">
          <iframe
            src={`${fileUrl}#toolbar=0&navpanes=0`}
            className="h-full w-full flex-grow border-none"
          />
        </div>
      )}
    </details>
  );
}

export default DocViewer;
