import { useNavigate } from 'react-router-dom';
import { NotesIcon } from '../../ui-elements';
import FeatureCard from '../../ui-elements/feature-card/FeatureCard';
import PageHeader from './page-header/PageHeader';

const allFeatures = [
  {
    id: 'createHeadnote',
    icon: NotesIcon,
    title: 'Headnote Creator',
    actionText: 'Create',
    navTo: '/dashboard/create-headnote',
  },
];

export function DashboardHome() {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <PageHeader title="Dashboard" />
      <div className="p-4">
        {allFeatures.map((feat) => (
          <FeatureCard
            key={feat.id}
            title={feat.title}
            actionText={feat.actionText}
            icon={feat.icon}
            onClick={() => navigate(feat.navTo)}
          />
        ))}
      </div>
    </div>
  );
}

export default DashboardHome;
