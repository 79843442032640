import {
  ArrowDownOnSquareIcon,
  ClipboardDocumentIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Button, Toggle } from '../../ui-base';
import { toast } from '../../util';
import MenuDropdown from '../menu-dropdown/MenuDropdown';
import { MenuItem } from '../types';

interface Props {
  title: string;
  description: string;
  isDownloadable?: boolean;
  toggleLabel?: string;
  toggleEnabled?: boolean;
  onToggleChange?: (enabled: boolean) => void;
  onExport?: () => void;
  onCopy?: () => void;
}
export function CardPanel(props: Props) {
  const {
    title,
    description,
    isDownloadable = false,
    toggleEnabled,
    toggleLabel,
    onToggleChange,
    onExport,
    onCopy,
  } = props;

  const onCopyCb = () => onCopy && onCopy();

  const onSave = () => {
    onExport && onExport();
    toast('Headnote downloaded as pdf');
  };

  const menuItems: MenuItem[] = [
    {
      name: 'Save',
      onClick: onSave,
    },
    {
      name: 'Copy',
      onClick: onCopyCb,
    },
  ];
  if (onToggleChange) {
    const item = {
      name: toggleEnabled ? 'Show Special Format' : 'Show Standard Format',
      onClick: () => onToggleChange(!toggleEnabled),
    };
    menuItems.push(item);
  }

  return (
    <div className="flex h-full flex-col rounded-md border px-16 py-4">
      <div className="mb-4 flex h-20 items-center justify-between border-b border-gray-400 p-2">
        <h2 className="text-xl font-bold text-gray-700">{title}</h2>
        {onToggleChange && (
          <div className="hidden lg:block">
            <Toggle
              enabled={!!toggleEnabled}
              onChange={onToggleChange}
              label={toggleLabel}
            />
          </div>
        )}
        {isDownloadable && (
          <>
            <div className="hidden items-center text-lawmatrix-purple lg:flex">
              <Button
                onClick={onSave}
                variant="icon"
                className="flex items-center"
              >
                Save
                <ArrowDownOnSquareIcon className="h-6 w-8" />
              </Button>
              <Button
                onClick={onCopy}
                variant="icon"
                className="flex items-center"
              >
                Copy
                <ClipboardDocumentIcon className="h-6 w-8" />
              </Button>
            </div>
            <div className="block lg:hidden">
              <MenuDropdown
                menuButton={<EllipsisVerticalIcon className="h-5 w-5" />}
                items={menuItems}
              />
            </div>
          </>
        )}
      </div>
      <p
        className={clsx('flex-grow overflow-y-auto whitespace-pre-line p-3')}
        dangerouslySetInnerHTML={{ __html: description }}
      >
        {/* {description} */}
      </p>
    </div>
  );
}

export default CardPanel;
